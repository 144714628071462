import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Container,
  IconButton,
  Drawer,
  List,
  ListItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";

const Layout = () => {
  const [drawerOpen, setDrawerOpen] = useState(false); // State for drawer
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Adjust breakpoint for responsiveness

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const navLinks = (
    <Box
      sx={{
        display: "flex",
        gap: 3,
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      <Link
        to="/about"
        style={{
          color: "#fff",
          textDecoration: "none",
          fontSize: isMobile ? "0.9rem" : "1rem",
        }}
        onMouseEnter={(e) =>
          (e.currentTarget.style.textDecoration = "underline")
        }
        onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
      >
        About Us
      </Link>
      <Link
        to="/contact"
        style={{
          color: "#fff",
          textDecoration: "none",
          fontSize: isMobile ? "0.9rem" : "1rem",
        }}
        onMouseEnter={(e) =>
          (e.currentTarget.style.textDecoration = "underline")
        }
        onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
      >
        Contact
      </Link>
      <a
        href="https://www.buymeacoffee.com/flybnb"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
          alt="Buy Me A Coffee"
          style={{
            height: isMobile ? "35px" : "48px",
            width: isMobile ? "125px" : "175px",
          }}
        />
      </a>
    </Box>
  );

  const mobileMenu = (
    <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
      <Box
        sx={{
          width: 150, // Adjust drawer width for better spacing
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 2,
        }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List sx={{ width: "100%", padding: 0 }}>
          {/* About Link */}
          <ListItem
            button
            component={Link}
            to="/about"
            sx={{
              margin: "10px 0", // Spacing between items
              padding: "8px 16px", // Comfortable padding
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: "bold",
                fontSize: "1.3rem", // Adjusted font size
                color: "white",
                ml: 1,
                display: "flex",
                alignItems: "center",
                textDecoration: "underline", // Always underlined
                gap: 1,
              }}
            >
              About
            </Typography>
          </ListItem>

          {/* Contact Link */}
          <ListItem
            button
            component={Link}
            to="/contact"
            sx={{
              margin: "10px 0",
              padding: "8px 16px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: "bold",
                fontSize: "1.3rem", // Adjusted font size
                color: "white",
                ml: 1,
                display: "flex",
                alignItems: "center",
                textDecoration: "underline", // Always underlined
                gap: 1,
              }}
            >
              Contact
            </Typography>
          </ListItem>

          {/* Donate Link */}
          <ListItem
            button
            component="a"
            href="https://www.buymeacoffee.com/flybnb"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              margin: "10px 0",
              padding: "8px 16px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: "bold",
                fontSize: "1.3rem", // Adjusted font size
                color: "white",
                ml: 1,
                display: "flex",
                alignItems: "center",
                textDecoration: "underline", // Always underlined
                gap: 1,
              }}
            >
              Donate
            </Typography>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      {/* Header / Navbar */}
      <AppBar position="static" sx={{ backgroundColor: "#121212" }}>
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            {/* Logo on the left */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: isMobile ? "flex-start" : "flex-start", // Center on mobile

                flexGrow: 1,
                mt: 2,
                mb: 2,
              }}
            >
              <img
                src="./new-logo.svg"
                alt="FlyBnB Logo"
                title="FlyBnB Logo"
                style={{
                  height: isMobile ? "55px" : "60px",
                  cursor: "pointer",
                }}
                onClick={() => (window.location.href = "/")}
              />
            </Box>
            {isMobile ? (
              <>
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer(true)}
                >
                  <MenuIcon />
                </IconButton>
                {mobileMenu}
              </>
            ) : (
              navLinks
            )}
          </Toolbar>
        </Container>
      </AppBar>
      {/* Just Launched Banner 
      <Box
        sx={{
          backgroundColor: "#5F5AA2",
          color: "#fff",
          textAlign: "center",
          py: isMobile ? 1 : 1, // Adjust padding for mobile
          px: isMobile ? 2 : 2,
          fontSize: isMobile ? "0.94rem" : "1rem", // Smaller font size on mobile
          fontWeight: "bold",
        }}
      >
        🚀 Just launched –{" "}
        <Link
          to="/contact"
          style={{
            color: "#fff",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          share your feedback!
        </Link>
      </Box> */}
      <Box component="main" sx={{ flex: 1, p: 0, mt: 2 }}>
        <Outlet />
      </Box>
      {/* Footer */}
      <Box
        component="footer"
        sx={{
          backgroundColor: "#121212",
          textAlign: "center",
          py: 3,
          mt: "auto",
          borderTop: "1px solid #333",
          color: "#fff",
        }}
      >
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ maxWidth: "600px", mx: "auto", mb: 1 }}
        >
          DISCLAIMER: I'm not affiliated with any booking website and do not
          intend to represent any. This is a passion project developed
          independently. Use at your own discretion. Your feedback is welcome!
        </Typography>
      </Box>
    </Box>
  );
};

export default Layout;

// src/components/HowItWorks.js

import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

const HowItWorks = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // Styles for the icons
  const iconStyles = {
    width: "80px",
    height: "80px",
    objectFit: "contain",
    marginBottom: "16px",
  };

  // Styles for the container
  const containerStyles = {
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    justifyContent: "center",
    alignItems: "center", // Center items vertically
    gap: 2,
    mt: 5,
    maxWidth: "1200px",
    width: "100%",
    mx: "auto",
    px: 2,
  };

  // Styles for each entry
  const entryStyles = {
    flex: 1,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  // Styles for the symbols
  const symbolStyles = {
    fontSize: "48px",
    fontWeight: "bold",
    color: theme.palette.text.primary,
    mx: isMobile ? 0 : 1,
    my: isMobile ? 2 : 0,
  };

  return (
    <Box sx={{ py: 8, px: 5, backgroundColor: "#272727" }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontWeight: "800",
          mb: 0,
          fontFamily: "'Roboto', sans-serif",
          textAlign: "center",
          color: "white",
          fontSize: {
            xs: "2rem", // Mobile font size
            sm: "2rem", // Halfscreen
            md: "2.5rem", // Desktop
          },
        }}
      >
        How It Works{" "}
      </Typography>

      <Box sx={containerStyles}>
        {/* Flight Entry */}
        <Box sx={entryStyles}>
          <img
            src="/flight-icon.svg" // Replace with your flight icon path
            alt="Flight Icon"
            style={iconStyles}
          />
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
            Flights
          </Typography>
          <Box sx={{ maxWidth: "350px", mx: "auto" }}>
            <Typography variant="body1" sx={{ color: "text.secondary" }}>
              Get flight options & prices for your selected dates and
              destinations. Quick filters for best value, cheapest, or fastest
              flight options, including flight itinerary.
            </Typography>
          </Box>
        </Box>

        {/* Plus Sign */}
        <Typography sx={symbolStyles}>+</Typography>

        {/* Stay Entry */}
        <Box sx={entryStyles}>
          <img
            src="/stay-icon.svg" // Replace with your stay icon path
            alt="Stay Icon"
            style={iconStyles}
          />
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
            AirBnB
          </Typography>
          <Box sx={{ maxWidth: "350px", mx: "auto" }}>
            <Typography variant="body1" sx={{ color: "text.secondary" }}>
              Find and compare Airbnb stays with average prices for economy,
              standard, and premium listings at a glance. Filter for reviews and
              stay type.
            </Typography>
          </Box>
        </Box>

        {/* Equals Sign */}
        <Typography sx={symbolStyles}>=</Typography>

        {/* FlyBnB Entry */}
        <Box sx={entryStyles}>
          <img
            src="/both-icon.svg" // Replace with your FlyBnB logo path
            alt="FlyBnB combined search icon"
            style={{
              ...iconStyles,
              width: "90px", // Larger width for FlyBnB logo
              height: "90px", // Larger height for FlyBnB logo
            }}
          />
          <Typography variant="h4" sx={{ fontWeight: "bold", mb: 1 }}>
            FlyBnB
          </Typography>
          <Box sx={{ maxWidth: "350px", mx: "auto" }}>
            <Typography variant="body1" sx={{ color: "text.secondary" }}>
              Combine flight and Airbnb stay searches to get the total cost of
              your trip instantly. The total price updates with your filters.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HowItWorks;
